$(function() {
  var $pagination = $('#pagination');
  if ($pagination.length == 0) return;

  var $more = $pagination.find('.c-pagination__more');
  var $loader = $pagination.find('.c-pagination__loader');
  var $items = $pagination.find('.c-pagination__items');

  $items.hide();

  var $next = $items.find('.c-pagination__item--current').next();
  if ($next.length == 0) return;

  $more.css('display', 'inline-block').on('click', function(e) {
    e.preventDefault();

    $more.hide();
    $loader.css('display', 'inline-block');

    $.get($next.find('a').attr('href'), function(data, textStatus, jqXHR) {
      var $html = $(data);

      var $items = $html.find('.c-postlist .c-postlist__items').html();
      $('.c-postlist .c-postlist__items').append($items);

      $loader.hide();
      $next = $html.find('.c-pagination__items .c-pagination__item--current').next();
      if ($next.length > 0) {
        $more.css('display', 'inline-block');
      }
    });
  });
});
