$(function() {
  var $body     = $('body'),
    $header     = $('#header'),
    $navigation = $('#navigation'),
    $button     = $('#navigation-button-open, #navigation-button-close'),
    $links      = $('.js-navigation-link'),
    was_mobile = $button.css('display') === 'none' ? false : true,
    now_mobile = was_mobile
    scrollTop = 0,
    didResize = true;

  if (!$header.length) return true;

  $window.on('resize', function () {
    didResize = true;
  });

  $button.on('click', function(event) {
    event.preventDefault();
    event.stopPropagation();

    var navigation_visible = $navigation.attr('aria-hidden') === 'true' ? false : true;

    $button.attr('aria-expanded', !navigation_visible);
    $navigation.attr('aria-hidden', navigation_visible);
    $header.toggleClass('header--navigation-expanded', !navigation_visible);

    if(navigation_visible) {
      $body.removeClass('no-scroll').css('top', '');
      $window.scrollTop(scrollTop);
    }
    else {
      scrollTop = $window.scrollTop();
      $body.addClass('no-scroll').css('top', -scrollTop + 'px');
    }
  });

  $links.on('click', function() {
    if ($navigation.attr('aria-hidden') === 'false') {
      $button.trigger('click');
    }
  });

  $(document).click(function(e) {
    if ($(e.target).closest('#navigation').length == 0 && $navigation.attr('aria-hidden') === 'false') {
      $button.trigger('click');
    }
  });

  // $document.on('click', function(event) {
  //   if(Modernizr.touchevents) {
  //     var $this = $(event.target);
  //
  //     if($this.hasClass('js-navigation-link')) {
  //       if(!$this.parent().hasClass('is-active')) {
  //         event.preventDefault();
  //
  //         $links.parent().removeClass('is-active');
  //
  //         $this.parent().addClass('is-active');
  //       }
  //     } else {
  //       $links.parent().removeClass('is-active');
  //     }
  //   }
  // });

  (function loop () {
    requestAnimationFrame(loop);

    if (didResize) {
      now_mobile = $button.css('display') === 'none' ? false : true;

      if(!now_mobile && was_mobile) {
        var navigation_visible = $navigation.attr('aria-hidden') === 'true' ? false : true;
        if (navigation_visible) {
          $button.trigger('click');
        }
      }

      was_mobile = now_mobile;
      didResize = false;
    }
  })();

});
